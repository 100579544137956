import React, { useEffect, useState } from "react";
import PageHeader from "../../components/Common/PageHeader";
import { currency } from "../../helpers/constants";
import {
  callDepositeAmount,
  callWithdrawAmount,
  getRegulatoryAndParameters,
} from "../../helpers/repository";
import { showMessage } from "../../components/Common/Toaster";
import {
  getUserBalance,
  getUserNumber,
} from "../../components/Common/Utils/helpers";
import ReactModal from "react-modal";

function Withdrawal() {
  const [num, setNumber] = useState("2000");
  const [acc_name, setAccountName] = useState("");
  const [acc_number, setAccountNumber] = useState(getUserNumber());
  const [bank, setbank] = useState("853d0598-9c01-41ab-ac99-48eab4da1513");
  const [minimum_withdraw, set_minimum_withdraw] = useState(0);
  const [loading, setLoading] = useState(null);
  const [countDown, setCountDown] = useState(0);
  const [runTimer, setRunTimer] = useState(false);
  const [openModel, setModelOpen] = useState(false);
  const [openDetailModel, setDetailModelOpen] = useState(false);
  const [showError, setshowError] = useState(false);
  const [errorMessage, seterrorMessage] = useState("");

  const handleChange = (e) => {
    const value = e.target.value.replace(/\D/g, "");
    setNumber(value);
  };
  const customModalStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "300px!important",
      // backgroundColor: "#272727",
      color: "black",
      padding: "0px",
    },
  };

  useEffect(() => {
    let timerId;

    if (runTimer) {
      setCountDown(60 * 5);
      timerId = setInterval(() => {
        setCountDown((countDown) => countDown - 1);
      }, 1000);
    } else {
      clearInterval(timerId);
    }

    return () => clearInterval(timerId);
  }, [runTimer]);

  useEffect(() => {
    if (countDown < 0 && runTimer) {
      console.log("expired");
      setRunTimer(false);
      setCountDown(0);
    }
  }, [countDown, runTimer]);

  const _handleModalOpen = (e) => {
    // setModelOpen(true);
    setDetailModelOpen(true);
  };
  const handleInputChange = (e) => {
    const inputValue = e;
    const regex = /^[A-Za-z]*$/g;
    if (regex.test(inputValue) || inputValue == "") {
      // Input matches the pattern or is empty, update the state
      setAccountName(inputValue);
    }
  };

  const _handleBankDetails = (e) => {
    setDetailModelOpen(false);
    setModelOpen(true);
  };
  useEffect(() => {
    getRegulatoryAndParameters(handleSuccess);
  }, []);
  const handleSuccess = (res) => {
    set_minimum_withdraw(res?.betting_limits?.minimum_withdrawal);
  };
  const _handleSubmitWithdraw = (e) => {
    setModelOpen(false);
    setRunTimer((t) => !t);
    setLoading(1);

    // console.log(JSON.parse(localStorage.getItem("user")));
    let data = {};
    data.amount = num;
    data.user_id = JSON.parse(localStorage.getItem("user"))?.user?.id;
    data.currency = currency;
    data.mobile_prefix = "251";
    data.acc_name = acc_name;
    data.acc_number = acc_number;
    data.bank = bank;

    callWithdrawAmount(data)
      .then((res) => {
        showMessage("success", res?.data?.message);
        setRunTimer(false);
      })
      .catch((e) => {
        if (e?.response?.data?.show) {
          setshowError(true);
          seterrorMessage(e?.response?.data?.error);

          setTimeout(() => {
            setshowError(false);
            seterrorMessage("");
          }, 10000);
        } else {
          showMessage("error", e?.response?.data?.error);
        }
        setAccountName(null);
        setRunTimer(false);
      });
  };
  return (
    <>
      <PageHeader title={"Withdraw"} />
      <div className="BLM-commonPage">
        <div className="BLM-commonPage-content BLM-common">
          <div className="space-3"></div>
          <div className="row">
            <p
              className="text-center
            "
            >
              Your Account Balance:{" "}
              <b className="text-danger">{getUserBalance()}</b>
            </p>
          </div>

          <div className="space-5"></div>

          <div className="row">
            <div className="col-3">
              <button
                className={`btn BLM-btnPrimary BLM-btnMedium btn-outline-secondary btn_hover_primary ${
                  num == "2000" ? "active" : ""
                }`}
                onClick={() => {
                  setNumber("2000");
                }}
              >
                <span className="BLM-btnTxt">2000</span>
              </button>
            </div>
            <div className="col-3">
              <button
                className={`btn BLM-btnPrimary BLM-btnMedium btn-outline-secondary btn_hover_primary ${
                  num == "5000" ? "active" : ""
                }`}
                onClick={() => {
                  setNumber("5000");
                }}
              >
                <span className="BLM-btnTxt">5000</span>
              </button>
            </div>
            <div className="col-3">
              <button
                className={`btn BLM-btnPrimary BLM-btnMedium btn-outline-secondary btn_hover_primary ${
                  num == "10000" ? "active" : ""
                }`}
                onClick={() => {
                  setNumber("10000");
                }}
              >
                <span className="BLM-btnTxt">10000</span>
              </button>
            </div>
            <div className="col-3">
              <button
                className={`btn BLM-btnPrimary BLM-btnMedium btn-outline-secondary btn_hover_primary ${
                  num == "50000" ? "active" : ""
                }`}
                onClick={() => {
                  setNumber("50000");
                }}
              >
                <span className="BLM-btnTxt">50000</span>
              </button>
            </div>
          </div>

          {showError ? (
            <div>
              <div className="space-10"></div>
              <div className="row">
                <h6 className="text-center text-danger">{errorMessage}</h6>
              </div>
            </div>
          ) : null}
          <div className="space-10"></div>

          <div className="row">
            <div className="col-lg-12">
              <div className="BLM-form-group">
                <div className="BLM-form-label">
                  Enter Amount to Withdraw (minimum withdrawal {currency}{" "}
                  {minimum_withdraw}):
                </div>
                <div
                  className="BLM-form-control BLM-form-control-pin"
                  id="errorMessagePassClass"
                >
                  <input
                    type="text"
                    id="userPass"
                    onkeyup="return numberMobile(event)"
                    onkeypress="return enterUserName(event)"
                    placeholder="XXXX"
                    required
                    pattern="[0-9]*"
                    value={num}
                    onChange={handleChange}
                  />
                </div>
                <div className="BLM-form-errorMsg">
                  <span id="displayErrorMessage"></span>
                </div>
              </div>
            </div>
          </div>

          <div className="space-5"></div>
          {runTimer ? (
            <button
              className="btn BLM-btnDanger active BLM-btnLarge"
              id="disableLoginButtonClick"
            >
              <span className="spinner-grow spinner-grow-sm"></span>
            </button>
          ) : (
            <button
              className="btn BLM-btnDanger active BLM-btnLarge"
              id="disableLoginButtonClick"
              onClick={() => {
                _handleModalOpen();
              }}
            >
              <span className="BLM-btnTxt">Withdraw</span>
            </button>
          )}

          {
            <div className="row img_row">
              <ReactModal
                isOpen={openModel}
                style={customModalStyles}
                // className="modal-content-react"
              >
                <div className="modal-content">
                  <div className="modal-header">
                    <div className="modal-title h4">Withdraw</div>
                    <button
                      type="button"
                      className="btn-close"
                      aria-label="Close"
                      onClick={() => {
                        setModelOpen(false);
                      }}
                    ></button>
                  </div>
                  <div className="modal-body">
                    <p style={{ fontSize: "17px" }}>
                      Are you sure you want to withdraw {num} amount?
                    </p>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      style={{ padding: "8px 17px" }}
                      onClick={() => {
                        _handleSubmitWithdraw();
                      }}
                    >
                      Yes
                    </button>
                    <button
                      type="button"
                      className="btn btn-danger"
                      style={{ padding: "8px 17px" }}
                      onClick={() => {
                        setAccountName(null);
                        setModelOpen(false);
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </ReactModal>
            </div>
          }
          {
            <div className="row img_row">
              <ReactModal
                isOpen={openDetailModel}
                style={customModalStyles}
                // className="modal-content-react"
              >
                <div className="modal-content">
                  <div className="modal-header">
                    <div className="modal-title h4">Withdraw</div>
                    <button
                      type="button"
                      className="btn-close"
                      aria-label="Close"
                      onClick={() => {
                        setDetailModelOpen(false);
                      }}
                    ></button>
                  </div>
                  <div className="modal-body">
                    <div className="BLM-form-group">
                      <div className="BLM-form-label">Select Bank:</div>
                      {/* <div
                        className="BLM-form-control-group"
                        id="regErrorMessageClass"
                      > */}
                      {/* <div className="BLM-form-control">
                        <label>
                          <input
                            type="radio"
                            value="option1"
                            style={{
                              width: "auto",
                              height: "auto",
                            }}
                            // checked={selectedOption === "option1"}
                            // onChange={handleOptionChange}
                          />
                          Option 1
                        </label>
                        <br />
                        <label>
                          <input
                            type="radio"
                            value="option2"
                            style={{
                              width: "auto",
                              height: "auto",
                            }}
                            // checked={selectedOption === "option2"}
                            // onChange={handleOptionChange}
                          />
                          Option 2
                        </label>
                      </div> */}
                      {/* </div> */}
                      <div className="row">
                        <div className="col-3">
                          <button
                            className={`btn BLM-btnPrimary BLM-btnMedium btn-outline-secondary btn_hover_primary bank-button ${
                              bank == "853d0598-9c01-41ab-ac99-48eab4da1513"
                                ? "active"
                                : ""
                            }`}
                            onClick={() => {
                              setbank("853d0598-9c01-41ab-ac99-48eab4da1513");
                            }}
                          >
                            <span className="BLM-btnTxt">TELEBIRR</span>
                          </button>
                        </div>
                        <div className="col-3">
                          <button
                            className={`btn BLM-btnPrimary BLM-btnMedium btn-outline-secondary btn_hover_primary bank-button ${
                              bank == "953d0598-4e01-41ab-ac93-t9eab4da1u13"
                                ? "active"
                                : ""
                            }`}
                            onClick={() => {
                              setbank("953d0598-4e01-41ab-ac93-t9eab4da1u13");
                            }}
                          >
                            <span className="BLM-btnTxt">MPESA</span>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="BLM-form-errorMsg"></div>
                    <div className="BLM-form-group">
                      <div className="BLM-form-label">
                        Enter Account Number:
                      </div>
                      <div
                        className="BLM-form-control-group"
                        id="regErrorMessageClass"
                      >
                        <div className="BLM-form-control">
                          <input
                            type="number"
                            id="acc_number"
                            placeholder="Enter Account Number"
                            readOnly
                            required
                            // onCopy={(e) => e.preventDefault()} // Disable copying
                            // onPaste={(e) => e.preventDefault()} // Disable pasting
                            // onChange={(e) => {
                            //   setAccountNumber(e.target.value);
                            // }}
                            value={acc_number}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="BLM-form-errorMsg"></div>
                    <div className="BLM-form-group">
                      <div className="BLM-form-label">Enter Account Name:</div>
                      <div
                        className="BLM-form-control-group"
                        id="regErrorMessageClass"
                      >
                        <div className="BLM-form-control">
                          <input
                            type="text"
                            id="acc_name"
                            placeholder="Enter Account Name"
                            maxLength={9}
                            required
                            onCopy={(e) => e.preventDefault()} // Disable copying
                            onPaste={(e) => e.preventDefault()} // Disable pasting
                            onChange={(e) => {
                              handleInputChange(e.target.value);
                            }}
                            value={acc_name}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      style={{ padding: "8px 17px" }}
                      onClick={() => {
                        _handleBankDetails();
                      }}
                    >
                      Continue
                    </button>
                    <button
                      type="button"
                      className="btn btn-danger"
                      style={{ padding: "8px 17px" }}
                      onClick={() => {
                        setAccountName(null);
                        setDetailModelOpen(false);
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </ReactModal>
            </div>
          }
          <div className="space-5"></div>

          <div className="row">
            <div className="col-lg-5"></div>
            <div className="separator col-lg-2">OR</div>
            <div className="col-lg-5"></div>
          </div>
          <div className="space-5"></div>

          <div className="withdrawal-bottom-section">
            <div className="BLM-commonHeader">
              <b className="BLM-commonHeader-title">How to Withdraw</b>
            </div>
          </div>
          <div className="space-5"></div>

          <div>
            {/* <h3 className="BLM-common-mainTitle">
              Via SMS(applies to all networks)
            </h3>
            <ol>
              <li>
                To withdraw, send the work W#Amount ti 29663 <br /> E.g. W#500
                to 29663
              </li>
              <li>
                Your Sakabet account will be deducted and your mobile money
                wallet funded with the amount.
              </li>
            </ol> */}
            <h3 className="BLM-common-mainTitle">
              Via Website(applies to all networks)
            </h3>
            <ol>
              <li>Click on Menu then click on Withdraw</li>
              <li>Chose one of the preselected values or</li>
              <li>
                Enter the amount you'd like to withdraw and click on Withdraw
              </li>
              <li>
                Your playable account will be deducted and your mobile money
                wallet funded with the amount
              </li>
              <li>
                You will receive a confirmation message once your withdrawal has
                been successful.
              </li>
            </ol>
          </div>
        </div>
      </div>
    </>
  );
}

export default Withdrawal;
