import React, { useEffect, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { showSideBar } from "../../store/actions";
import { logoutUser } from "../../store/Auth/actions";
import { setActiveTab } from "../../store/actions";
import { getFlagEmoji, getSportName } from "./Utils/helpers";
import { getFixturesBySportId } from "../../store/Fixtures/actions";
import { useHistory } from "react-router-dom";
import _ from "lodash";
import {getAllLeaguesBySport, getRegulatoryAndParameters, getUserInfo} from "../../helpers/repository";
import { info } from "toastr";
import '../../../node_modules/flag-icons/css/flag-icons.min.css'
import {currency} from "../../helpers/constants";
function SideMenuContent(props) {
  let history = useHistory();
  const dispatch = useDispatch();
  const [location_collapsed, set_location_collapsed] = useState(true);
  const [sportId, setSportId] = useState(null);
  const [Leagues, setLeagues] = useState([]);
  const activeTab = useSelector((state) => state?.CommonState?.activeTab ?? "");
  // const Leagues = useSelector((state) => state?.Leagues.Index ?? []);
  const Locations = useSelector((state) => state?.Locations.Index ?? []);
  const Sports = useSelector((state) => state.Sports?.Index);
  const [leagueFixtures, setLeagueFixtures] = useState([]);
  const [league, setleague] = useState();
  const [user, setUser] = useState(null);
  const [isRafActive, setIsRafActive] = useState(0);
  const isLoggedIn = sessionStorage.getItem("token") ? true : false;

  const handleLogout = (e) => {
    e.preventDefault();
    const { history } = props;
    dispatch(logoutUser(history));
  };
  useEffect(() => {
    if(Sports?.data){
      setSportId(Sports?.data.find((s) => s.name == "Soccer")?.id);
    }
  }, [Sports]);

  useEffect(() => {
    if (sportId) {
      getAllLeaguesBySport(
          sportId,
          successGetLeaguesBySport,
          errorGetLeaguesBySport
      );
    }
  }, [sportId]);

  useEffect(() => {
    if (isLoggedIn) getUserInfo(successUserInfoCall, errorUserInfoCall);
  }, [isLoggedIn]);
  useEffect(() => {
    getRegulatoryAndParameters(handleSuccess);
  }, [isLoggedIn]);

  const handleSuccess = (res) => {
    setIsRafActive(res?.betting_limits?.is_raf_active);
  };
  const successUserInfoCall = (d) => {
    setUser(d.data);
  };

  const errorUserInfoCall = (e) => {
    showMessage(e);
  };
  const successGetLeaguesBySport = (d) => {
    setLeagues(d);
  };
  const errorGetLeaguesBySport = () => {
    console.log("error");
  };


  const getleagueInfo = (league) => {
    // TODO: change league.sport_id to actual sport_id in league
    props.history.push(`/league-details?sport_id=${1}&id=${league.bb_id}`);
  };

  return (
    <>
      {isLoggedIn ? (
        <div className="BLM-tabs-content">
          <div id="BLM-sideMenu-allSports" className="BLM-tab-pane active">
            <div className="BLM-menuPanel-quickLinks">
              <div className="BLM-categoryListLayout BLM-accordion">
                <div
                  className="Account_number"
                  id="quicklinks_2"
                  onClick={() => {
                    window.getAccordianLeagues("quicklinks_2");
                  }}
                >
                  <span>{user?.phone_number}</span>
                  <span>
                    {currency} {user?.cash_balance ?? 0}
                  </span>
                </div>
              </div>
            </div>
            <hr />
            <div className="BLM-menuPanel-quickLinks">
              <div className="BLM-categoryListLayout BLM-accordion">
                <div className="Cash_Bonus_Balance">
                  <div className="row mb-1">
                    <span className="col">Cash Balance</span>
                    <span className="col text-end">
                      {currency} {user?.cash_balance ?? 0}
                    </span>
                  </div>
                  <div className="row mb-1">
                    <span className="col">Bonus Balance</span>
                    <span className="col text-end">
                      {currency} {user?.bonus_balance ?? 0}
                    </span>
                  </div>
                  {/* <div className="row">
                    <span className="col">Signup Balance</span>
                    <span className="col text-end">
                      {currency} {user?.signup_freebet ?? 0}
                    </span>
                  </div> */}
                </div>
              </div>
            </div>
            <hr />
            <div className="BLM-menuPanel-quickLinks">
              <div className="BLM-categoryListLayout BLM-accordion">
                <div className="row" style={{ padding: "5px" }}>
                  <div className="col">
                    <button
                      class="btn BLM-btn BLM-btnSuccess active BLM-btnLarge"
                      onClick={() => {
                        dispatch(showSideBar(false));
                        props.history.push("/Deposits");
                      }}
                    >
                      <span class="BLM-btnTxt">Deposit</span>
                    </button>
                  </div>
                  <div className="col">
                    <button
                      class="btn BLM-btn BLM-btnDanger active BLM-btnLarge"
                      onClick={() => {
                        dispatch(showSideBar(false));
                        props.history.push("/withdrawals");
                      }}
                    >
                      <span class="BLM-btnTxt">Withdraw</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div
              className="BLM-accordion-content"
              id="accrd-SideMenu-AllSports"
            >
              <Link to="/mybets">
                <div className="BLM-categoryList SB-has-viewMore">
                  <div className="BLM-categoryList">
                    <div
                      className="BLM-categoryListItem"
                      value="football"
                      onClick={() => {
                        dispatch(showSideBar(false));
                      }}
                    >
                      <i className="sb-icon_myBets" />
                      My Bets
                    </div>
                  </div>
                </div>
              </Link>
              <Link to="/bonuses-awarded">
                <div className="BLM-categoryList SB-has-viewMore">
                  <div className="BLM-categoryList">
                    <div
                      className="BLM-categoryListItem"
                      value="football"
                      onClick={() => {
                        dispatch(showSideBar(false));
                      }}
                    >
                      <i className="sb-icon_withdraw-history" />
                      Bonuses Awarded
                    </div>
                  </div>
                </div>
              </Link>
              <Link to="/transaction-history">
                <div className="BLM-categoryList">
                  <div
                    className="BLM-categoryListItem"
                    value="football"
                    onClick={() => {
                      dispatch(showSideBar(false));
                    }}
                  >
                    <i className="sb-icon_accountHistory" />
                    Transaction History
                  </div>
                </div>
              </Link>
              {isRafActive ? (
                <Link to="/refer-a-friend">
                  <div className="BLM-categoryList SB-has-viewMore">
                    <div className="BLM-categoryList">
                      <div
                        className="BLM-categoryListItem"
                        value="football"
                        onClick={() => {
                          dispatch(showSideBar(false));
                        }}
                      >
                        <i className="sb-icon_changePassword" />
                        Refer A Friend
                      </div>
                    </div>
                  </div>
                </Link>
              ) : (
                ""
              )}

              <Link to="/change-pin">
                <div className="BLM-categoryList SB-has-viewMore">
                  <div className="BLM-categoryList">
                    <div
                      className="BLM-categoryListItem"
                      value="football"
                      onClick={() => {
                        dispatch(showSideBar(false));
                      }}
                    >
                      <i className="sb-icon_changePassword" />
                      Change Pin
                    </div>
                  </div>
                </div>
              </Link>
              <Link to="/profile">
                <div className="BLM-categoryList SB-has-viewMore">
                  <div className="BLM-categoryList">
                    <div
                      className="BLM-categoryListItem"
                      value="football"
                      onClick={() => {
                        dispatch(showSideBar(false));
                      }}
                    >
                      <i className="sb-icon_withdraw-history" />
                      Profile
                    </div>
                  </div>
                </div>
              </Link>
            </div>

            <div className="BLM-menuPanel-quickLinks mt-5">
              <div className="BLM-categoryListLayout BLM-accordion">
                <div className="row" style={{ padding: "5px" }}>
                  <div className="col-lg-1"></div>
                  <div className="col-lg-10">
                    <button
                      class="btn BLM-btnPrimary BLM-btnLarge btn-outline-secondary btn_hover_primary"
                      onClick={handleLogout}
                    >
                      <span class="BLM-btnTxt">Logout</span>
                    </button>
                  </div>
                  <div className="col-lg-1"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="BLM-tabs-content">
          <div id="BLM-sideMenu-allSports" className="BLM-tab-pane active">
            <div className="BLM-menuPanel-topLeagues">
              <div className="BLM-categoryListLayout BLM-accordion">
                <div
                  className="BLM-categoryList-header BLM-accordion-header BLM-arrowAfter"
                  id="topleagues_5"
                  onClick={() => {
                    window.getAccordianLeagues("topleagues_5");
                  }}
                >
                  Top leagues
                </div>
                <div
                  className="BLM-accordion-content"
                  id="accrd-SideMenu-Topleagues"
                >
                  <div className="BLM-categoryList SB-has-viewMore">
                    <div className="SB-has-viewMore-container">
                      {Leagues?.length ? (
                        <>
                          {_.sortBy(Leagues, "name")?.map((league, index) => {
                            return (
                              <div
                                className="BLM-accordion BLM-innerAccordion"
                                key={index}
                              >
                                <div
                                  className="BLM-categoryListItem BLM-accordion-header BLM-arrowAfter"
                                  id="Q_127"
                                  style={{ display: "none" }}
                                  onClick={() => {
                                    window.getAccordianLeagues("Q_127");
                                  }}
                                >
                                  <div className>
                                    <span className="BLM-flag flag-de" />{" "}
                                    Germany
                                  </div>
                                </div>
                                <div className="BLM-accordion-content">
                                  <div
                                    className="BLM-categoryListItem"
                                    onClick={() => {
                                      document
                                        .getElementById("sidebar_trigger")
                                        .click();
                                      getleagueInfo(league);
                                    }}
                                  >
                                    {league?.name}
                                  </div>
                                  {/* <div class='BLM-categoryListItem' onClick='getleagueInfo(event)'>German Bundesliga 3</div><div class='BLM-categoryListItem' onClick='getleagueInfo(event)'>German Bundesliga 2</div> */}
                                </div>
                              </div>
                            );
                          })}
                        </>
                      ) : null}
                    </div>
                    <div
                      className="SB-viewMore"
                      onClick={() => {
                        window.showMoreToggle("accrd-SideMenu-Topleagues");
                      }}
                    >
                      <div className="SB-bgFade" />
                      <div className="SB-viewButton" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="BLM-categoryListLayout BLM-accordion">
                <div
                  className="BLM-categoryList-header BLM-accordion-header BLM-arrowAfter"
                  id="allcountries"
                  onClick={() => {
                    window.getAccordianLeagues("allcountries");
                  }}
                >
                  All countries
                </div>
                <div
                  className="BLM-accordion-content"
                  id="accrd-SideMenu-AllCountries"
                >
                  <div className="BLM-categoryList SB-has-viewMore">
                    <div className="SB-has-viewMore-container">
                      {Locations?.length ? (
                        <>
                          {_.sortBy(Locations, "name")?.map(
                            (location, index) => {
                              return (
                                <div
                                  className={`BLM-accordion BLM-innerAccordion collapsed`}
                                  // onClick={() => {
                                  //   set_location_collapsed(!location_collapsed);
                                  // }}
                                  key={index}
                                >
                                  <div
                                    className="BLM-categoryListItem BLM-accordion-header BLM-arrowAfter"
                                    id={`Location_${location?._id}`}
                                    onClick={() => {
                                      window.getAccordianLeagues(
                                        `Location_${location?._id}`
                                      );
                                    }}
                                  >
                                    <div className>
                                      <span
                                        // className={`BLM-flag flag-${getFlagEmoji(
                                        className={`fi fi-${getFlagEmoji(
                                          location?.name
                                        )}`}
                                      />
                                      {location?.name}
                                    </div>
                                  </div>
                                  <div className="BLM-accordion-content">
                                    {location?.tournaments?.map((league) => {
                                      return (
                                        <div
                                          className="BLM-categoryListItem"
                                          onClick={(event) => {
                                            document
                                              .getElementById("sidebar_trigger")
                                              .click();
                                            getleagueInfo(league);
                                          }}
                                        >
                                          {league?.name}
                                        </div>
                                      );
                                    })}
                                  </div>
                                </div>
                              );
                            }
                          )}
                        </>
                      ) : null}
                    </div>
                    <div
                      className="SB-viewMore"
                      onClick={() => {
                        window.showMoreToggle("accrd-SideMenu-AllCountries");
                      }}
                    >
                      <div className="SB-bgFade" />
                      <div className="SB-viewButton" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="BLM-menuPanel-allSports">
              <div className="BLM-categoryListLayout BLM-accordion">
                <div
                  className="BLM-categoryList-header BLM-accordion-header BLM-arrowAfter"
                  id="Allsports_0"
                  onClick={() => {
                    window.getAccordianLeagues("Allsports_0");
                  }}
                >
                  All Sports
                </div>
                <div
                  className="BLM-accordion-content"
                  id="accrd-SideMenu-AllSports"
                >
                  <div className="BLM-categoryList SB-has-viewMore">
                    <div className="SB-has-viewMore-container">
                      <div className="BLM-categoryList">
                        <div
                          className="BLM-categoryListItem BLM-inPlay"
                          // onClick="highlightFooterTab(event,this.getAttribute('data-footer-inplay')); getInplay('Football');"
                          data-footer-inplay="In-play"
                        >
                          <div className="BLM-iconSvg">
                            <svg
                              version="1.1"
                              xmlns="http://www.w3.org/2000/svg"
                              xmlnsXlink="http://www.w3.org/1999/xlink"
                              x="0px"
                              y="0px"
                              viewBox="0 0 24 24"
                              style={{
                                enableBackground: "new 0 0 24 24",
                              }}
                              xmlSpace="preserve"
                            >
                              <path
                                d="M14.8,10.6l-5,3.3c-0.2,0.2-0.6,0-0.6-0.3V7c0-0.3,0.3-0.5,0.6-0.3l5,3.3C15,10.1,15,10.4,14.8,10.6z M22.6,4.8H1.4v10.9
                                                            h21.2V4.8 M22.7,3.4C23.4,3.4,24,4,24,4.7v11.1c0,0.7-0.6,1.3-1.3,1.3H1.3c-0.7,0-1.3-0.6-1.3-1.3V4.7C0,4,0.6,3.4,1.3,3.4H22.7
                                                            L22.7,3.4z M18,20v-0.5c0-0.3-0.3-0.6-0.6-0.6H6.6c-0.3,0-0.6,0.3-0.6,0.6V20c0,0.3,0.3,0.6,0.6,0.6h10.8C17.7,20.6,18,20.3,18,20z
                                                            "
                              />
                            </svg>
                          </div>{" "}
                          Live Now
                        </div>
                        {Sports?.data?.map((sport, i) => (
                          <>
                            <div
                              className="BLM-categoryListItem"
                              value="football"
                              onClick={() => {
                                props.history.push(
                                  `/sports-details?id=${sport.bb_id}`
                                );
                                dispatch(setActiveTab(sport?.name));
                                dispatch(showSideBar(false));
                                dispatch(getFixturesBySportId(sport?.bb_id));
                              }}
                              key={i}
                            >
                              <i
                                className={`sb-icon_sport-${getSportName(
                                  sport?.name
                                )}`}
                              />

                              {sport?.name}
                            </div>
                          </>
                        ))}
                      </div>
                    </div>
                    <div
                      className="SB-viewMore"
                      onClick={() => {
                        window.showMoreToggle("accrd-SideMenu-AllSports");
                      }}
                    >
                      <div className="SB-bgFade" />
                      <div className="SB-viewButton" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="BLM-sideMenu-myAccount" className="BLM-tab-pane">
            <div className="BLM-menuPannel-myAccount-beforeLogin">
              <div className="BLM-myAccount-beforeLogin-container">
                <div className="text-center">
                  Please login to view your Account
                </div>
                <Link to="/login">
                  <button className="btn BLM-btnPrimary BLM-btnSmall BLM-btnLogin">
                    Login
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default withRouter(SideMenuContent);
